<template>
  <div>
    <SiteNavigationBar></SiteNavigationBar>
    <Transition name="modal">
      <AppModal
        v-if="authModalPromise"
        class="modal-container fix-page"
        window-class="max-w-[calc(100vw-16px)] max-h-[calc(100svh-32px)] min-[412px]:max-w-[480px] w-full bg-white rounded-md md:rounded-lg shadow-lg py-2 md:p-4 overflow-y-auto"
        @click="authModalPromise?.resolve()"
      >
        <SiteAuth class="w-full" :title="$t('auth.loginToSavePropertyHint')" />
      </AppModal>
    </Transition>
    <main
      class="w-full !min-h-[100lvh] min-h-[100vh] px-4 pt-[calc(88px+1rem)] pb-8"
    >
      <section
        id="property-navigation"
        class="w-full flex flex-col gap-4 justify-center items-center pt-6 mb-6"
      >
        <div class="text-neutral text-3xl leading-7 text-center mx-auto mb-4">
          {{ $t('common.oops') }}
        </div>
        <p class="mb-2">
          {{ $t('common.pageNotFound') }}
        </p>
        <p class="hidden">
          {{ error }}
        </p>
        <AppButton class="uppercase px-4 h-[48px] rounded mb-4" :to="`/`">
          {{ $t('common.goBackHome') }}
        </AppButton>
        <template v-if="properties?.length">
          <p class="text-lg">{{ $t('search.otherItems') }}:</p>
          <div
            class="flex flex-wrap gap-4 mx-auto"
            :style="{
              maxWidth: 220 * 5 + 16 * 4 + 'px'
            }"
          >
            <SitePropertyCard
              v-for="property in properties"
              :key="property.id"
              class="w-full sm:w-[220px] min-h-[290px]"
              v-bind="property"
              show-favorite
              @update:favorite="setPropertyAsFavorite(property, $event)"
            />
          </div>
        </template>
      </section>
    </main>
    <SiteFooter v-once :fix-locale="locale" />
  </div>
</template>

<script setup lang="ts">
// import useI18nFix from './composables/use-i18n-fix';

import { createSearchParams } from '@/helpers/search';
import { ExternalPromise } from '@/types/common';
import type {
  PropertyCardProps,
  PropertyDocumentResponse,
  QueryResponse
} from '@/types/properties';

const AppModal = defineAsyncComponent(
  () => import('@/async-components/App/Modal.vue')
);
const SiteAuth = defineAsyncComponent(
  () => import('@/async-components/Site/Auth.vue')
);
const SiteNavigationBar = defineAsyncComponent(
  () => import('@/async-components/Site/NavigationBar.vue')
);
const SitePropertyCard = defineAsyncComponent(
  () => import('@/async-components/Site/PropertyCard.vue')
);

// await useI18nFix();

defineProps({
  error: Object
});

// const localePath = useLocalePath();

const {
  public: { apiUrl }
} = useRuntimeConfig();

const { get } = useHttpMethods();

const { formatProperty, setFavorite, updateFavorites } = useProperties();

const { addToast } = useToasts();

const user = inject('user') as Ref<{ email?: unknown } | null>;
const isAuthenticated = computed(() => Boolean(user?.value?.email));

const { t, locale } = useI18n();

const { language } = useLanguage();

const { triggerEvent } = useHubspot();

const propertiesResponse = ref<QueryResponse | null>(null);

const propertiesRaw = ref<PropertyDocumentResponse[]>([]);

const properties = computed<PropertyCardProps[]>(() => {
  return propertiesRaw.value?.map(formatProperty) || [];
});

watch(
  () => [isAuthenticated.value, properties.value],
  async () => {
    if (!isAuthenticated.value) return;

    await updateFavorites(properties.value.map(value => value.id));
  }
);

const authModalPromise = ref<ExternalPromise<void> | null>(null);

watch(isAuthenticated, () => {
  if (isAuthenticated.value && authModalPromise.value) {
    authModalPromise.value.resolve();
    authModalPromise.value = null;
  }
});

async function setPropertyAsFavorite(
  property: PropertyCardProps,
  value: boolean
) {
  let check = false;

  if (!isAuthenticated.value) {
    check = true;
    authModalPromise.value = new ExternalPromise<void>();
    await authModalPromise.value;
    authModalPromise.value = null;
    if (!isAuthenticated.value) {
      return;
    }
  }

  await setFavorite(property.id, value, check);
  triggerEvent('pe23281212_property_save', {
    saved: property.to
  });

  if (value) {
    addToast({
      title: t('property.propertySaved'),
      type: 'success'
    });
  } else {
    addToast({
      title: t('property.propertyRemoved'),
      type: 'info'
    });
  }
}

async function triggerSearch() {
  const queryParams = createSearchParams(
    {
      perPage: 10,
      includeFields:
        'bathsFull,bathsHalf,bedrooms,city,county,full,geopoint,id,listPrice,mlsId,photos,postalCode,propertyArea,state,streetName,subType,type'
    },
    language.value as 'en' | 'es'
  );

  const url = apiUrl + '/search/?' + new URLSearchParams(queryParams);

  propertiesResponse.value = null;
  propertiesRaw.value = [];

  propertiesResponse.value = (
    await get<{ data: QueryResponse<PropertyDocumentResponse> }>(url)
  ).data;

  propertiesRaw.value = propertiesResponse.value?.hits.map(hit => hit.document);
}

onMounted(() => {
  updateFavorites(null, true).then(() => {
    triggerSearch();
  });
});
</script>
