import { PropertyCardProps } from './properties';

export const isObject = (
  value: unknown
): value is Record<string | number | symbol, unknown> => {
  return Boolean(value) && typeof value === 'object' && !Array.isArray(value);
};

export class ExternalPromise<T> {
  promise!: Promise<T>;
  resolve!: (value: T | PromiseLike<T>) => void;
  reject!: (reason?: unknown) => void;
  status!: 'pending' | 'fulfilled' | 'rejected';

  then!: Promise<T>['then'];
  catch!: Promise<T>['catch'];
  finally!: Promise<T>['finally'];

  constructor() {
    let res: (value: T | PromiseLike<T>) => void;
    let rej: (reason?: unknown) => void;
    this.promise = new Promise((resolve, reject) => {
      res = resolve;
      rej = reject;
    });
    this.status = 'pending';
    this.resolve = (arg: T | PromiseLike<T>) => {
      this.status = 'fulfilled';
      res(arg);
    };
    this.reject = (arg: unknown) => {
      this.status = 'rejected';
      rej(arg);
    };
    this.then = this.promise.then.bind(this.promise);
    this.catch = this.promise.catch.bind(this.promise);
    this.finally = this.promise.finally.bind(this.promise);
  }
}

export type MaybeCallback<T> = T | (() => T);

// eslint-disable-next-line @typescript-eslint/ban-types
export type Prettify<T> = { [K in keyof T]: T[K] } & {};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface SelectOption<T = any> {
  divider?: boolean;
  text: string;
  value: T;
  disabled?: boolean;
  hidden?: boolean;
}

export type QueryValue = string | number | boolean | string[] | number[];

// blog

export type MetaItem =
  | {
      name: string;
      content: string;
    }
  | {
      property: string;
      content: string;
    };

export interface PostCard {
  image: string;
  imageAlt: string;
  title: string;
  excerpt: string;
  to: string;
  category?: string;
  categoryLink?: string;
}

export interface Post {
  title: string;
  content: string;
  date: string;
  slug: string;
  categories: {
    name: string;
    path: string;
    slug: string;
    parent?: {
      name: string;
      path: string;
      slug: string;
    };
  }[];
  translation: {
    slug: string;
    parentCategorySlug?: string;
    categorySlug: string;
  };
  tags: {
    name: string;
    path: string;
  }[];
  relatedPosts: PostCard[];
  previousPost?: {
    title: string;
    slug: string;
    categories: {
      name: string;
      path: string;
    }[];
  };
  nextPost?: {
    title: string;
    slug: string;
    categories: {
      name: string;
      path: string;
    }[];
  };
  carousels: {
    items: PropertyCardProps[];
  }[];
  image: string;
  imageAlt: string;
  seo: {
    title: string;
    description: string;
    meta: MetaItem[];
    schema?: string;
  };
}

export interface PostFromResponse {
  title: string;
  excerpt: string;
  link: string;
  slug: string;
  featuredImage?: {
    node?: {
      sourceUrl: string;
      altText: string;
    };
  };
  categories: {
    nodes: {
      name: string;
      slug: string;
      parent: {
        node: {
          name: string;
          slug: string;
        };
      };
    }[];
  };
}

export interface PillarPage {
  isPillarPage: boolean;
  pillarPageData: {
    heroTitle: string;
    heroSubtitle: string;
    heroImage: {
      altText: string;
      sourceUrl: string;
    };
    initialParagraph: string;
    image1: {
      altText: string;
      sourceUrl: string;
    };
    cta1: string;
    ctaAnchor1: string;
    subTitle1: string;
    paragraph1: string;
    subTitle2: string;
    paragraph2: string;
    video2: string;
    cta2: string;
    subTitle3: string;
    paragraph3: string;
    image4: {
      altText: string;
      sourceUrl: string;
    };
    subTitle4: string;
    paragraph4: string;
    subTitle5: string;
    buildingsId: string;
    embedCode: string;
    faqTitle: string;
    faqQuestions: {
      faqQuestionTitle: string;
      faqAnwser: string;
    }[];
    relatedPost: PostFromResponse[];
  };
}

export interface Page {
  title: string;
  content: string;
  date: Date;
  slug: string;
  translationSlug: string;
  previousPage?: {
    title: string;
    slug: string;
  };
  nextPage?: {
    title: string;
    slug: string;
  };
  image: string;
  imageAlt: string;
  pilarPage: PillarPage;
  seo: {
    title: string;
    description: string;
    meta: MetaItem[];
    schema?: string;
  };
}

export interface PageFromResponse extends Omit<PostFromResponse, 'categories'> {
  elementorContent: string;
}
