<template>
  <component
    :is="$attrs.href ? 'a' : $attrs.to ? NuxtLink : 'button'"
    :style="$attrs.style"
    :disabled="loading || disabled"
    v-bind="buttonAttrs($attrs)"
    class="app--button relative flex items-center justify-center font-sans disabled:opacity-50 leading-tight transition-all select-none"
    :class="[loading || disabled ? 'pointer-events-none' : '', $attrs.class]"
  >
    <span
      class="button-inner-container w-full flex flex-nowrap gap-[0.25em] items-center justify-center text-center"
      :class="{
        '!opacity-0': loading
      }"
    >
      <slot> {{ text }} </slot>
      <Icon
        v-if="icon"
        :path="icon"
        :class="{
          '!opacity-0': loading
        }"
      />
    </span>
    <span
      v-if="loading"
      class="absolute w-full h-full left-0 top-0 flex items-center justify-center"
    >
      <slot name="loading">
        <template v-if="typeof loading === 'string'">
          {{ loading }}
        </template>
        <Icon :path="mdiLoading" class="h-1/2 w-full animate-spin" />
      </slot>
    </span>
  </component>
</template>

<script lang="ts">
import { mdiLoading } from '@mdi/js';
import type { PropType } from 'vue';
export default {
  inheritAttrs: false
};
</script>

<script lang="ts" setup>
const NuxtLink = resolveComponent('NuxtLink');

defineProps({
  text: {
    type: String
  },
  icon: {
    type: String
  },
  disabled: {
    type: Boolean
  },
  loading: {
    type: [Boolean, String] as PropType<boolean | string>,
    default: false
  }
});

function buttonAttrs(attrs) {
  const { type, to, href } = attrs;
  if (!type && !to && !href) {
    return {
      ...attrs,
      type: 'button'
    };
  } else {
    return attrs;
  }
}
</script>

<style lang="scss">
.app--button {
  &.variant-primary,
  &:not([class*='variant-']) {
    @apply bg-primary focus-visible:bg-primary-light hover:bg-primary-light active:bg-primary-lighter;
    @apply text-white;
    @apply font-bold;
    @apply border border-transparent focus-visible:border-primary-lighter border-2;
    @apply outline-none;
  }

  &.variant-neutral {
    @apply bg-neutral-light focus-visible:bg-neutral-lighter hover:bg-neutral-lighter active:bg-neutral-lightest;
    @apply text-white;
    @apply font-bold;
    @apply border border-transparent focus-visible:border-primary-lighter border-2;
    @apply outline-none;
  }

  &.variant-generic-hover,
  &.variant-generic {
    @apply bg-[color:var(--button-color)];
    @apply font-bold;
    @apply border border-white/0 focus-visible:border-white/50 border-2;
    @apply outline-none overflow-hidden;
    &::after {
      content: '';
      @apply absolute left-0 top-0 w-full h-full;
      @apply bg-current;
      @apply opacity-0 focus-visible:opacity-10;
    }
  }

  &.variant-generic {
    &::after {
      @apply hover:opacity-10 active:opacity-20;
    }
  }

  &.variant-outline {
    @apply border #{'!important'};
    @apply bg-white hover:bg-neutral-lighter/10 active:bg-neutral-lighter/20;
    @apply border-neutral-lighter;
    @apply focus-visible:ring-2 focus-visible:ring-line/50 focus-visible:z-[2];
    @apply text-neutral;
    @apply font-normal;
  }

  &.variant-search {
    @apply border;
    @apply bg-transparent active:bg-neutral-lighter/10;
    @apply border border-neutral-lighter hover:border-neutral-lighter/50 active:border-neutral-lighter/30;
    @apply focus-visible:ring-2 focus-visible:ring-line/50;
    @apply text-neutral-light;
    @apply font-bold;
  }

  &.variant-primary-outline {
    @apply border;
    @apply bg-transparent active:bg-primary/10;
    @apply border-primary focus-visible:border-primary/50 hover:border-primary/50 active:border-primary/30;
    @apply outline-none;
    @apply text-neutral;
    @apply font-bold;
  }

  &.variant-invisible {
    @apply border border-[2px] border-transparent;
    @apply bg-transparent;
    @apply focus-visible:border-current;
    @apply font-bold;
    @apply overflow-hidden;
    @apply outline-none;
    &::before {
      content: '';
      @apply absolute left-0 top-0 w-full h-full;
      @apply bg-current;
      @apply opacity-0 hover:opacity-10 active:opacity-20;
      @apply transition-opacity;
    }
  }

  &.variant-social-icon {
    // padding: 0 !important;
    border-radius: 9999px !important;

    @apply border;
    @apply border-current;
    @apply focus-visible:z-[2];
    @apply text-current;
    @apply font-normal;

    &:focus-visible::before {
      content: '';
      @apply absolute left-0 top-0 w-full h-full;
      @apply ring-2 ring-current opacity-50;
    }

    &::after {
      content: '';
      @apply absolute left-0 top-0 w-full h-full;
      @apply bg-current;
      @apply rounded-[inherit];
      @apply opacity-0 focus-visible:opacity-10;
      @apply hover:opacity-10 active:opacity-20;
    }

    .mdi-icon {
      @apply min-w-[75%] w-3/4 h-3/4;
    }
  }

  // internal styles

  &.icon-button {
    // padding: 0 !important;
    border-radius: 9999px !important;

    .mdi-icon {
      @apply min-w-[75%] w-3/4 h-3/4;
    }
  }

  .mdi-icon {
    @apply my-[-8px];
  }

  &.left-button {
    & > *:first-child {
      @apply justify-start;
    }
  }

  &.placeholder-button,
  .placeholder-buttons & {
    @apply text-transparent bg-gray-lighter animate-pulse pointer-events-none opacity-100 border-none outline-none #{!important};
  }
}
</style>
