export default {
  home: {
    seo: {
      title: 'Bienes raíces en venta y alquiler en Miami',
      description:
        ' Las mejores opciones de bienes raíces en Miami para comprar, vender o rentar propiedades con confianza y asesoría experta de PFS Realty Group.'
    },
    hero: {
      title: 'Bienes raíces en venta y renta en Miami',
      p: 'Propiedades',
      f: 'Financiamiento',
      s: 'Servicios'
    },
    used: 'Usado',
    new: 'Nuevo',
    rent: 'Rentar',
    buy: 'Usado',
    location: 'Ciudad, código postal o condado',
    currentLocation: 'Ubicación actual',
    contactFormTitle:
      '¡Contacta a uno de nuestros asesores para más información!',
    contactFormText:
      'Accede a nuestra asesoría gratuita de servicios 360° y obtén hasta un 70% de financiamiento en tu inversión.',
    properties_title: 'Nuestro portafolio de propiedades',
    propertiesPortfolio: 'Apartamentos y Casas en venta y renta en Miami',
    apartmentsPortfolioDescription: 'Nuestro portafolio de departamentos',
    housesPortfolioDescription: 'Nuestro portafolio de casas',
    find_property_title: 'ENCUENTRA TU PROPIEDAD POR CIUDAD',
    find_property_text:
      'Tenemos un amplio portafolio de propiedades perfectas para ti.',
    clients: {
      title:
        '2,000 clientes en todo el mundo han invertido en Miami gracias a PFS Realty',
      features: {
        one: {
          title: '22 años de experiencia en ventas de propiedades en EE.UU.',
          description:
            'Nuestra prolongada trayectoria como líderes en el mercado de bienes raíces en Miami nos posiciona como tu mejor opción. Tenemos un historial comprobado que respalda nuestro servicio confiable y resultados exitosos.'
        },
        two: {
          title: '65 asesores en 12 países de América y Europa',
          description:
            'Con una red internacional de asesores, ofrecemos perspectivas globales y localizadas que enriquecen nuestra capacidad para encontrar oportunidades inigualables. Desde el asesoramiento hasta el cierre del trato, optimizamos tus proyectos de inversión inmobiliaria.'
        },
        three: {
          title: 'Más de 200 clientes satisfechos confían en nosotros',
          description:
            'La confianza depositada en nosotros por nuestros clientes es el reflejo de nuestra dedicación y dominio del mercado de bienes raíces. Somos una de las inmobiliarias en Miami más confiables y respetadas. ¡Únase y experimente el éxito con PFS Realty!'
        }
      }
    },
    services: {
      title: 'Servicios completos 360°',
      properties: {
        title: 'Adquiera la vivienda ideal',
        description:
          'Encuentre la propiedad perfecta en Miami y compra inmuebles nuevos y usados de alta calidad. Cada asesor inmobiliario cuenta con amplia experiencia y conocimiento para brindarle el asesoramiento especializado que necesita.'
      },
      financing: {
        title: 'Financiamiento a su medida',
        description:
          'La compra de un inmueble suele requerir de un financiamiento sólido y adaptado a la capacidad económica del comprador. Por eso, ofrecemos préstamos hipotecarios flexibles y personalizados para extranjeros con mínimo 30% de entrada. Adaptamos las condiciones a sus necesidades.'
      },
      extra_income: {
        title: 'Genere ingresos extras alquilando',
        description:
          'Le brindamos una atención y asistencia integral para convertir tu propiedad en una fuente de ganancias en dólares, manejando todas las etapas del arrendamiento para maximizar un retorno de inversión óptimo.'
      },
      management: {
        title: 'Administración de propiedades',
        description:
          'Contamos con un equipo preparado para el mantenimiento profesional y el cuidado preventivo y correctivo de su vivienda. Optimizamos recursos y beneficios para asegurar una administración eficiente en su inversión inmobiliaria.'
      },
      legal: {
        title: 'Asesoría legal y contable completa',
        description:
          'Nuestro equipo multidisciplinario de profesionales proporciona información experta para aclarar todas las dudas sobre los aspectos legales y contables que involucran la compra, venta y alquiler de bienes inmuebles. Aseguramos el cumplimiento legal de los contratos.'
      }
    },
    events: {
      title: 'Próximos eventos',
      formMessage: 'Déjanos tus datos para separar un cupo en nuestro evento',
      registerButton: 'Reserva Entrada'
    },
    testimonialsTitle: 'Testimoniales',
    news_title: 'Nuestro Blog',
    popupFormTitle:
      'Suscríbete a nuestro newsletter y obtén información de primera mano sobre el mercado inmobiliario en Miami y Orlando.',
    popupFormMessage:
      'Consejos exclusivos, tendencias del mercado y posibilidades únicas. ¡No te pierdas esta oportunidad para impulsar tu éxito en bienes raíces!'
  },
  calculator: {
    title: 'Simulador de crédito hipotecario',
    description:
      'Esta herramienta le permite simular el pago mensual de un préstamo hipotecario, considerando factores como el capital e interés, el impuesto predial anual, la cuota de administración y la prima de seguro mensual. \n' +
      'Al ingresar los datos sobre el precio del inmueble, la cuota inicial y la tasa de interés, usted puede obtener un cálculo preciso de sus cuotas mensuales, facilitando la compra y financiamiento de su vivienda a través de una entidad financiera. \n',
    hint: 'Esto es esencial para la educación financiera y la planificación de la compra de hogar propio, permitiéndole gestionar mejor su historial crediticio y ahorros.',
    monthlyPayments: 'Cuotas mensuales',
    principalInterest: 'Capital e intereses',
    propertyTax: 'Impuesto predial anual',
    associationFee: 'Cuota de administración',
    principalInterestLegend: 'Capital e intereses',
    propertyTaxLegend: 'Impuesto predial anual',
    associationFeeLegend: 'Cuota de administración',
    insuranceFee: 'Prima de seguro',
    homePrice: 'Precio',
    propertyTaxYearly: 'Predial anual',
    associationFeeMonthly: 'Cuota de administración mensual',
    insuranceFeeMonthly: 'Prima de seguro mensual',
    downPayment: 'Cuota inicial (%)',
    interestRate: 'Tasa de Interés (%)',
    interestRatePlaceholder: 'Tasa de interés (%)',
    loanType: 'Tipo de crédito',
    loanType10Year: 'Fijo a 10 Años',
    loanType15Year: 'Fijo a 15 Años',
    loanType20Year: 'Fijo a 20 Años',
    loanType30Year: 'Fijo a 30 Años',
    disclaimer: '*Los cálculos son un estimado.'
  },
  marketReport: {
    help:
      'Mediante nuestro informe inmobiliario de Miami, se puede seleccionar  la zona, el tipo de propiedad y el resto de las opciones para ver la información actualizada, comparar precios de propiedades en diversas áreas, evaluar el impacto de las tendencias e inversiones en el sector, y prever cambios en el mercado para planificar de manera óptima sus estrategias de compra, venta o arrendamiento. <br/>' +
      'Esta herramienta permite acceder a datos actualizados y detallados, segmentados por provincia, ciudad, parroquia y tipo de inmueble, resultando fundamental para tomar decisiones informadas y planificadas en el sector inmobiliario. No se pierda esta valiosa información y consulte el informe completo para optimizar sus inversiones.<br/>' +
      'Seleccione la zona, el tipo de propiedad y el resto de las opciones para ver la información actualizada'
  },
  blog: {
    title: 'Oportunidades en Miami y el sur de Florida',
    subtitle:
      'Descubre las últimas tendencias en el mercado inmobiliario y mantente informado con nuestras noticias exclusivas. ¡No te pierdas ninguna actualización sobre el sector y toma decisiones acertadas para tu futuro hogar o inversión!'
  }
};
